html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "InterRegular";
  src: local("InterRegular"),
    url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: "InterMedium";
  src: local("InterMedium"),
    url("./assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: "InterBold";
  src: local("InterBold"),
    url("./assets/fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: "BaiJamjureeRegular";
  src: local("BaiJamjureeRegular"),
    url("./assets/fonts/BaiJamjuree/BaiJamjuree-Regular.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}


@font-face {
  font-family: "BaiJamjureeMedium";
  src: local("BaiJamjureeMedium"),
    url("./assets/fonts/BaiJamjuree/BaiJamjuree-Medium.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}
